export const Chat = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 0.5C4.0295 0.5 0 3.634 0 7.5C0 9.684 1.287 11.634 3.302 12.9175C3.04 14.072 2.4295 15.7845 1 17.5C3.879 17.02 5.7195 15.6195 6.858 14.292C7.546 14.423 8.2605 14.5 9 14.5C13.9705 14.5 18 11.366 18 7.5C18 3.634 13.9705 0.5 9 0.5Z"
            fill="#0076FA"
        />
        <path
            d="M9 8.5C9.55228 8.5 10 8.05228 10 7.5C10 6.94772 9.55228 6.5 9 6.5C8.44772 6.5 8 6.94772 8 7.5C8 8.05228 8.44772 8.5 9 8.5Z"
            fill="white"
        />
        <path
            d="M13 8.5C13.5523 8.5 14 8.05228 14 7.5C14 6.94772 13.5523 6.5 13 6.5C12.4477 6.5 12 6.94772 12 7.5C12 8.05228 12.4477 8.5 13 8.5Z"
            fill="white"
        />
        <path
            d="M5 8.5C5.55228 8.5 6 8.05228 6 7.5C6 6.94772 5.55228 6.5 5 6.5C4.44772 6.5 4 6.94772 4 7.5C4 8.05228 4.44772 8.5 5 8.5Z"
            fill="white"
        />
    </svg>
);

export const Youtube = () => (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.1438 8.66094L12.0094 6.14375L7.1438 3.60547V8.66094Z" fill="white" />
        <path
            opacity="0.12"
            d="M7.1438 3.60547L11.4118 6.45313L12.0094 6.14375L7.1438 3.60547Z"
            fill="#420000"
        />
        <path
            d="M17.8102 2.72812C17.8102 2.72812 17.6345 1.49063 17.0933 0.942187C16.4116 0.225 15.6454 0.225 15.287 0.182812C12.7779 0 9.00352 0 9.00352 0H8.99648C8.99648 0 5.22218 0 2.69895 0.182812C2.34752 0.225 1.5814 0.225 0.892617 0.942187C0.358453 1.49063 0.182742 2.72812 0.182742 2.72812C0.182742 2.72812 0 4.19063 0 5.64609V7.01016C0 8.46562 0.182742 9.92109 0.182742 9.92109C0.182742 9.92109 0.358453 11.1586 0.899648 11.707C1.5814 12.4242 2.48105 12.4031 2.88169 12.4734C4.32253 12.6141 8.99648 12.6562 8.99648 12.6562C8.99648 12.6562 12.7778 12.6492 15.294 12.4664C15.6454 12.4242 16.4116 12.4242 17.1004 11.707C17.6415 11.1586 17.8173 9.92109 17.8173 9.92109C17.8173 9.92109 18 8.46562 18 7.01016V5.64609C17.993 4.19063 17.8102 2.72812 17.8102 2.72812ZM7.14101 8.6625V3.60703L12.0047 6.14531L7.14094 8.6625H7.14101Z"
            fill="url(#paint0_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="8.9964"
                y1="0"
                x2="8.9964"
                y2="12.66"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E52D27" />
                <stop offset="1" stopColor="#BF171D" />
            </linearGradient>
        </defs>
    </svg>
);

export const Help = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.6507 12.2367L12.2367 13.6507L9.76172 11.1757L11.1757 9.76172L13.6507 12.2367Z"
            fill="#9AAAB4"
        />
        <path
            d="M17.2986 14.4706L14.3591 11.5311C14.1715 11.3436 13.9172 11.2383 13.6521 11.2383C13.3869 11.2383 13.1326 11.3436 12.9451 11.5311L11.5311 12.9451C11.3436 13.1326 11.2383 13.3869 11.2383 13.6521C11.2383 13.9172 11.3436 14.1715 11.5311 14.3591L14.4706 17.2986C14.6551 17.4896 14.8758 17.642 15.1198 17.7468C15.3638 17.8516 15.6262 17.9068 15.8918 17.9091C16.1573 17.9114 16.4207 17.8608 16.6665 17.7602C16.9123 17.6596 17.1356 17.5111 17.3234 17.3234C17.5111 17.1356 17.6596 16.9123 17.7602 16.6665C17.8608 16.4207 17.9114 16.1573 17.9091 15.8918C17.9068 15.6262 17.8516 15.3638 17.7468 15.1198C17.642 14.8758 17.4896 14.6551 17.2986 14.4706Z"
            fill="#66757F"
        />
        <path
            d="M6.75 13.5C10.4779 13.5 13.5 10.4779 13.5 6.75C13.5 3.02208 10.4779 0 6.75 0C3.02208 0 0 3.02208 0 6.75C0 10.4779 3.02208 13.5 6.75 13.5Z"
            fill="#8899A6"
        />
        <path
            d="M6.75 11.5C9.37335 11.5 11.5 9.37335 11.5 6.75C11.5 4.12665 9.37335 2 6.75 2C4.12665 2 2 4.12665 2 6.75C2 9.37335 4.12665 11.5 6.75 11.5Z"
            fill="#BBDDF5"
        />
    </svg>
);

export const Academy = () => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.23943 0.130596C9.09019 0.0491929 8.90981 0.0491931 8.76057 0.130596L0.804737 4.47014C0.457305 4.65965 0.457306 5.15853 0.804738 5.34804L8.76055 9.68757C8.9098 9.76898 9.09019 9.76898 9.23944 9.68755L15.6242 6.20433C15.9574 6.02255 16.3636 6.26371 16.3636 6.64325V10.9545C16.3636 11.2307 16.5875 11.4545 16.8636 11.4545H17.5C17.7761 11.4545 18 11.2307 18 10.9545V5.20591C18 5.02293 17.9001 4.85458 17.7394 4.76696L9.23943 0.130596Z"
            fill="#494BA6"
        />
        <path
            d="M8.76046 14.6425L3.53319 11.7899C3.37261 11.7022 3.27271 11.5339 3.27271 11.351V9.21746C3.27271 8.83789 3.67903 8.59674 4.01222 8.77856L8.76046 11.3697C8.90975 11.4512 9.09021 11.4512 9.23949 11.3697L13.9877 8.77856C14.3209 8.59674 14.7273 8.83789 14.7273 9.21746V11.351C14.7273 11.5339 14.6273 11.7022 14.4668 11.7899L9.23949 14.6425C9.09021 14.7239 8.90975 14.7239 8.76046 14.6425Z"
            fill="#7C7EDB"
        />
    </svg>
);

export const Report = () => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 6.5C18 7.03043 17.7893 7.53914 17.4142 7.91421C17.0391 8.28929 16.5304 8.5 16 8.5H2C1.46957 8.5 0.960859 8.28929 0.585786 7.91421C0.210714 7.53914 0 7.03043 0 6.5V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H16C16.5304 0.5 17.0391 0.710714 17.4142 1.08579C17.7893 1.46086 18 1.96957 18 2.5V6.5Z"
            fill="#FFCC4D"
        />
        <path
            d="M3 0.5H2C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5L0 3.5L3 0.5ZM6 0.5L0 6.5C0 7.18 0.341 7.779 0.86 8.14L8.5 0.5H6ZM3.5 8.5H6L14 0.5H11.5L3.5 8.5ZM11.5 8.5L17.946 2.054C17.877 1.75364 17.7395 1.4733 17.5441 1.23494C17.3488 0.996574 17.101 0.806641 16.82 0.68L9 8.5H11.5ZM18 6.5V5L14.5 8.5H16C16.5304 8.5 17.0391 8.28929 17.4142 7.91421C17.7893 7.53914 18 7.03043 18 6.5Z"
            fill="#292F33"
        />
        <path d="M2 8.5H4.5V15.5H2V8.5ZM13.5 8.5H16V15.5H13.5V8.5Z" fill="#99AAB5" />
    </svg>
);

const icons = {
    help: Help,
    chat: Chat,
    academy: Academy,
    youtube: Youtube,
    report: Report,
};

export default icons;
