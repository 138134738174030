import { ChevronRightIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';

import { tw } from '@/utils/tw';

import type { ComponentProps } from 'react';

export interface Props {
    title: string;
    icon: (props: ComponentProps<'svg'>) => JSX.Element;
    btnId?: string;
    url?: string;
    isExternal?: boolean;
    onClick?: () => void;
}

const itemClassName = tw`group flex h-12 w-full cursor-pointer items-center rounded-lg p-4 outline-none hover:bg-gray-100`;

const Content = ({ title, icon }: Pick<Props, 'title' | 'icon'>) => {
    const Icon = icon;

    return (
        <>
            <div className="flex w-5">
                <Icon />
            </div>
            <span className="flex flex-1 pl-4 text-sm text-gray-500 transition-colors group-hover:text-gray-800">
                {title}
            </span>
            <div className="flex text-gray-500 transition-colors group-hover:text-gray-800">
                <ChevronRightIcon className="size-5" />
            </div>
        </>
    );
};

export const Item = ({ title, btnId, url, isExternal, icon, onClick }: Props) => {
    const isExternalLink = url && isExternal;
    const isInternalLink = url && !isExternal;

    const renderSwitch = () => {
        switch (true) {
            case isInternalLink:
                return (
                    <Link href={url} className={itemClassName}>
                        <Content title={title} icon={icon} />
                    </Link>
                );
            case isExternalLink:
                return (
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={itemClassName}
                    >
                        <Content title={title} icon={icon} />
                    </a>
                );
            default:
                return (
                    <button className={itemClassName} id={btnId} onClick={onClick}>
                        <Content title={title} icon={icon} />
                    </button>
                );
        }
    };

    return (
        <DropdownMenu.Item asChild>
            <li className="outline-none">{renderSwitch()}</li>
        </DropdownMenu.Item>
    );
};
