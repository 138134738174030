import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import get from 'lodash/get';
import { useMemo } from 'react';

import { getIsPerspectiveAdmin } from '@/app/user/helper';
import { getUser } from '@/app/user/models/user';
import { useAppSelector } from '@/core/redux/hooks';
import { UserGravatar } from '@/ui/components/UserGravatar';
import { cn } from '@/utils/cn';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';

import Body from './Body';
import Footer from './Footer';
import Header from './Header';

export const Avatar = () => {
    const user = useAppSelector(getUser);
    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    const name = get(user, 'attributes.fullName', 'John Doe');
    const email = get(user, 'attributes.email', 'john@doe.com');
    const isAdmin = useMemo(() => getIsPerspectiveAdmin(user), [user]);
    const isWorkspaceUser = !canCreate;

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'bump ml-2 size-10 cursor-pointer rounded-full outline-0 ring-0 ring-gray-100 transition-all hover:ring-2 sm:ml-6',
                        {
                            'ring-amber-200': isAdmin,
                        },
                    )}
                >
                    {isAdmin ? (
                        <div className="flex size-10 items-center justify-center rounded-full bg-amber-500 text-white">
                            <WrenchScrewdriverIcon className="size-5" />
                        </div>
                    ) : (
                        <UserGravatar
                            name={name}
                            email={email}
                            isCrmUser={isWorkspaceUser}
                            size={32}
                            fontSizeRatio={0.35}
                            className="w-full"
                        />
                    )}
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content
                sideOffset={6}
                align="end"
                side="bottom"
                className="origin-top-right data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in"
                asChild
            >
                <div className="w-64 select-none rounded-xl border bg-white shadow-dropdown">
                    <Header />

                    <Body isWorkspaceUser={!canCreate} />

                    <Footer isWorkspaceUser={!canCreate} />
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
};
